<template>
  <v-container fluid>
    <v-card-title>
      자주 묻는 질문 수정하기
    </v-card-title>

    <FAQ />
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import FAQ from '@/components/FAQ.vue'

export default defineComponent({
  components: {
    FAQ,
  },
})
</script>
